/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Button, Select, Input, Textarea, Icon, Modal, SelectActions } from "../../components";
import { axios, Globals, Constants } from "../../utils";
import CreateEditZone from "../zones/create_edit_zone";
import CreateEditClientCategory from "./create_edit_client_category";

const google = window.google;

class CreateEditClient extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
        latitude: 10.503703739685555,
        longitude: -66.91201470063201,
        address: '',
        code: '',
        seller_id: '',
      },
      modalZones: false,
      zones: props.zones,
      modalClientCategories: false,
      client_categories: props.client_categories,
      sellers: this.props.sellers ?? [],
      //enterprises: props.administrators,
      //retentions: props.retentions,

      // createFromPOS: Para validar que el cliente está siendo creado desde el módulo Pedidos -> Nuevo Pedido
      // Por lo tanto el usuario no tendrá acceso a seleccionar ubicación en el mapa ni modificar las zonas
      createFromPOS: Boolean(this.props.createFromPOS),
      hasMapPermission: this.hasPermission(Constants.ADMIN_FUNCTIONALITIES.VISIT_PLANNER) && !Boolean(this.props.createFromPOS),
      textButton: "Crear",
    };
  }

  ident_docs = {
    document_type: "V",
    document: ""
  };

  fiscal_docs = {
    document_type: "V",
    document: ""
  };

  map = null;

  async componentDidMount() {
    if (this.props.edit) {
      await this.edit();
    }

    if (this.state.hasMapPermission) {
      this.setMap();
    }
  }

  setMap = async () => {
    this.map = new google.maps.Map(document.getElementById('map-create-client'), {
      zoom: 13,
      center: new google.maps.LatLng(
        parseFloat(this.state.form.latitude),
        parseFloat(this.state.form.longitude)
      ),
      draggable: true,
      zoomControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      scaleControl: false,
      rotateControl: false
    });

    const marker = new google.maps.Marker({
      position: this.map.getCenter(),
      map: this.map,
      title: '',
      animation: google.maps.Animation.DROP,
      draggable: true,
    });

    google.maps.event.addListener(this.map, 'center_changed', () => {
      marker.setPosition(this.map.getCenter());
    });
  }

  getClientLocation = () => ({
    latitude: this.map.getCenter().lat(),
    longitude: this.map.getCenter().lng(),
  })

  getZones = () => {
    axios
      .post("web/admin/zones", {user_id: this.props.user.id})
      .then(({ data }) => {
        this.setState({ zones: data.zones });
      })
      .catch(() => Globals.showError())
      .then(() => Globals.quitLoading());
  };

  getClientCategories = () => {
    axios
      .post("admin/clients/categories", {user_id: this.props.user.id, is_select: true})
      .then(({ data }) => {
        this.setState({
          client_categories: data.categories
        });
      })
      .catch(() => Globals.showError())
      .then(() => Globals.quitLoading());
  };

  submit = async () => {
    if (this.state.hasMapPermission) {
      await this.setState(state => ({
        form: {
          ...state.form,
          ...this.getClientLocation(),
        }
      }));
    }

    if (Boolean(this.props.createFromPOS)) {
      if (!this.state.form?.name)
        return Globals.showError('Debe indicar el nombre');

      if (!this.state.form?.document)
        return Globals.showError('Debe indicar el RIF');
    }

    if (!this.state.form.document_type) {
      this.setState({
        ...this.state.form,
        document_type: "V",
        document: "V" + this.state.form.document
      });
    } else if (
      !this.state.form.document_type.indexOf("-") ||
      this.state.form.document_type
    ) {
      this.setState({
        ...this.state.form,
        document: this.state.form.document_type + this.state.form.document
      });
    }

    Globals.setLoading();
    axios
      .post(
        this.props.edit ? `web/admin/clients/edit/${this.state.form.id}` : "web/admin/clients/create",
        this.state.form
      )
      .then(res => {
        if (res.data.result) {
          this.setState({
            form: {}
          });
          Globals.showSuccess(res.data.msg);
          this.props.onClose(true);
        }
      })
      .catch(err => {
        let message = "Disculpe, ha ocurrido un error";
        if (err.response.status === 422) {
          message = err.response.data.error;
        }
        Globals.showError(message);
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  edit = async () => {
    console.log(this.props.edit)
    if (this.props.edit.element.identity_document) {
      if (
        !this.props.edit.element.identity_document.indexOf("-") ||
        parseInt(this.props.edit.element.identity_document)
      ) {
        this.ident_docs = {
          document_type: "V",
          document: this.props.edit.element.identity_document
        };
      } else {
        let division = this.props.edit.element.identity_document.split("-");
        this.ident_docs = {
          document_type: division[0],
          document: division[1]
        };
      }
    }
    if (this.props.edit.element.fiscal_identification) {
      if (
        !this.props.edit.element.fiscal_identification.indexOf("-") ||
        parseInt(this.props.edit.element.fiscal_identification)
      ) {
        this.fiscal_docs = {
          document_type: "V",
          document: this.props.edit.element.fiscal_identification
        };
      } else {
        let division = this.props.edit.element.fiscal_identification.split("-");
        this.fiscal_docs = {
          document_type: division[0],
          document: division[1]
        };
      }
    }
    await this.setState({
      form: {
        name: this.props.edit.element.name,
        email: this.props.edit.element.email,
        phone: this.props.edit.element.phone,
        address: this.props.edit.element.address,
        document: this.props.edit.element.document !== null ? String(this.props.edit.element.document).split('-').join('') : '',
        zone_id: this.props.edit.element.zone_id,
        client_category_id: this.props.edit.element.client_category_id,
        latitude: this.props.edit.element.latitude || this.state.form.latitude,
        longitude: this.props.edit.element.longitude || this.state.form.longitude,
        admin_id: this.props.edit.element.admin_id,
        id: this.props.edit.element.id,
        code: this.props.edit.element.code,
        seller_id: this.props.edit.element.seller_id,
        credit_limit: this.props.edit.element.credit_limit ?? '',
      },
      textButton: "Editar"
    });
  };

  change = e => {
    console.log(e)
    let enterprise_id = "";
    if (this.props.user.role === 1 || this.props.user.role === 2) {
      enterprise_id = this.props.user.id;
    } else {
      enterprise_id =
        parseInt(this.props.user.role) === 4
          ? parseInt(this.props.user.enterprise_users.enterprise_id)
          : parseInt(this.props.user.id);
    }
    this.setState({
      form: {
        enterprise_id: enterprise_id,
        creator_id: this.props.user.id,
        fiscal_document_type: this.fiscal_docs.document_type,
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  changeAddress = (address) => {
    this.change({ target: { value: address, name: 'address' } });
  }

  selectSuggestion = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      if (!results.length) return;

      const latLng = await getLatLng(results[0]);
      this.map.setCenter(latLng);
      this.changeAddress(address);

    } catch (error) {
      console.error('Error', error)
    }
  };

  setSelectedOption = async (value, key) => {
    this.setState({
      form: {
        ...this.state.form,
        [key]: value
      }
    });
    if (key === "document_type") this.ident_docs.document_type = value;
    if (key === "fiscal_document_type") this.fiscal_docs.document_type = value;
  };

  createZone = () => {
    document.getElementsByClassName('modal').item(0).style.zIndex = 1040;
    this.setState({ modalZones: true, edit: null });
  }

  createClientCategory = () => {
    document.getElementsByClassName('modal').item(0).style.zIndex = 1040;
    this.setState({ modalClientCategories: true, edit: null });
  }

  editZone = (item, index) => {
    document.getElementsByClassName('modal').item(0).style.zIndex = 1040;
    this.setState({
      modalZones: true,
      edit: {
        element: item,
        index: index,
      }
    })
  }

  editClientCategory = (item, index) => {
    document.getElementsByClassName('modal').item(0).style.zIndex = 1040;
    this.setState({
      modalClientCategories: true,
      edit: {
        element: item,
        index: index,
      }
    })
  }

  deleteZone = (item) => {
		Globals.confirm('¿Desea eliminar la zona: ' + item.name + '?', () => {
			Globals.setLoading("Eliminando...");
			axios.post('web/admin/zones/delete', { zone_id: item.id })
				.then(res => {
					Globals.showSuccess('zona eliminada con exito');
					this.getZones();
				})
				.catch(err => {
					Globals.showError(err.response.data.msg);
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
  }

  deleteClientCategory = (item) => {
		Globals.confirm('¿Desea eliminar la categoría: ' + item.name + '?', () => {
			Globals.setLoading("Eliminando...");
			axios.post('admin/clients/categories/delete', { client_category_id: item.id })
				.then(res => {
					Globals.showSuccess('Categoría eliminada con exito');
					this.getClientCategories();
				})
				.catch(err => {
					Globals.showError(err.response.data.msg);
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
  }

	close = async (reloading = false) => {
    document.getElementsByClassName('modal').item(0).style.zIndex = 1050;
		await this.setState({ modalZones: false, edit: null });
		if (reloading) await this.getZones();
	}

  closeClientCategories = async (reloading = false) => {
    document.getElementsByClassName('modal').item(0).style.zIndex = 1050;
		await this.setState({ modalClientCategories: false, edit: null });
		if (reloading) await this.getClientCategories();
	}

  hasPermission = (permissionId) => {
    if (!this.props.user.admin_functionalities.length) return false;
    return this.props.user.admin_functionalities.some(p => p.id === permissionId);
  }

  render() {
    const admin =
      this.props.user.role === 1 || this.props.user.role === 2 ? true : false;
    let selectEnterprise;
    // if (admin) {
    //   selectEnterprise = (
    //     <div className="col-md-6">
    //       <Select
    //         color=" "
    //         name="enterprise_id"
    //         label="Empresa"
    //         defaultname="Seleccione"
    //         className="material"
    //         labelClass="material"
    //         disabledfirst="false"
    //         onChange={this.change}
    //         value={this.state.form.enterprise_id}
    //         options={this.state.users.map(i => {
    //           return {
    //             value: i.id,
    //             label: i.name
    //           };
    //         })}
    //       />
    //     </div>
    //   );
    // }
    return (
      <>
				<Modal
					title={this.state.edit ? 'Editar zona' : "Nueva zona"}
					onClose={() => this.close()}
					visible={this.state.modalZones}
        >
					<CreateEditZone
						user={this.props.user}
						edit={this.state.edit}
						onClose={() => this.close(true)}
          />
				</Modal>

        {this.state.modalZones && (
          <div
            className="backdrop"
            onClick={() => this.close()}
          />
        )}

        <Modal
          title={this.state.edit ? 'Editar categoría' : "Nueva categoría"}
          onClose={() => this.closeClientCategories()}
          visible={this.state.modalClientCategories}
        >
          <CreateEditClientCategory
            user={this.props.user}
            edit={this.state.edit}
            onClose={() => this.closeClientCategories(true)}
          />
        </Modal>

        {this.state.modalClientCategories && (
          <div
            className="backdrop"
            onClick={() => this.closeClientCategories()}
          />
        )}

        <div className="container-create-edit-user">
          <div className="row">
            <div className="col-12 col-xl-12">
              <Input
                color=" "
                value={this.state.form.name}
                name="name"
                label="Nombre y apellido / Razón social"
                className="material"
                labelClass="material"
                onChange={this.change}
              />
            </div>
            {/* <div className="col-md-6">
                          <Input
                              color=" "
                              value={this.state.form.last_name}
                              name="last_name" 
                              label="Apellido"
                              onChange={this.change} />
                      </div> */}
            <div className="col-6">
              <Input
                color=" "
                value={this.state.form.document}
                name="document"
                label="RIF"
                className="material"
                labelClass="material"
                onKeyPress={Globals.onlyRifChars}
                maxLength={11}
                onChange={async (e) => {
                  const value = String(e.target.value).toUpperCase();

                  await this.change({ target: { name: 'document', value }});

                  await this.change({
                    target: {
                      name: 'document_type',
                      value: Globals.isValidRifPreffix(value)
                        ? String(value).substring(0,1)
                        : this.ident_docs.document_type,
                    }
                  });
                }}
                style={{ textTransform: 'uppercase' }}
              />
            </div>
            <div className="col-6">
              <Input
                color=" "
                value={this.state.form.code}
                name="code"
                label="Código del cliente"
                placeholder="(opcional)"
                className="material"
                labelClass="material"
                onChange={this.change}
              />
            </div>
            {/* <div className="col-md-6">
              <div style={{ display: "flex" }}>
                <Select
                  color=" "
                  label="Tipo"
                  name="fiscal_document_type"
                  defaultname="Seleccione"
                  className="material"
                  labelClass="material"
                  disabledFirst="true"
                  onChange={e =>
                    this.setSelectedOption(e.target.value, e.target.name)
                  }
                  value={
                    this.state.form.fiscal_document_type
                      ? this.state.form.fiscal_document_type
                      : "V"
                  }
                  options={Constants.TYPE_DOCUMENTS}
                />
                <Input
                  color=" "
                  value={this.state.form.fiscal_identification}
                  name="fiscal_identification"
                  label="RIF"
                  className="material"
                  labelClass="material"
                  onKeyPress={e => {
                    Globals.soloNumeros(e);
                  }}
                  maxLength={this.state.form.document_type === "J" ? 12 : 12}
                  onChange={this.change}
                />
              </div>
            </div> */}

            {selectEnterprise}

            <div className="col-6 col-xl-6">
              <Input
                color=" "
                value={this.state.form.phone}
                name="phone"
                label="Teléfono"
                className="material"
                labelClass="material"
                maxLength={11}
                onKeyPress={e => {
                  Globals.soloNumeros(e);
                }}
                onChange={this.change}
              />
            </div>

            <div className="col-6 col-xl-6">
              <SelectActions
                label="Zonas"
                placeholder="Seleccione"
                actionOptions={this.state.createFromPOS ? [] : [
                  {
                    onClick: () => this.createZone(),
                    content: (
                      <div>
                        <span>Añadir</span>
                        <i className="fa fa-regular fa-plus" style={{ marginLeft: '0.75rem' }} />
                      </div>
                    ),
                  }
                ]}
                options={this.state.zones.map((i, idx) => ({
                  value: i.id,
                  label: i.code_profit ? `${i.name} - ${i.code_profit}` : i.name,
                  actions: this.state.createFromPOS ? [] : [
                    <i
                      key={`zone-${i.value}-edit`}
                      className="fa fa-regular fa-edit"
                      title="Editar"
                      onClick={() => this.editZone(i, idx)}
                    />,
                    <i
                      key={`zone-${i.value}-close`}
                      className="fa fa-regular fa-close"
                      title="Eliminar"
                      onClick={() => this.deleteZone(i, idx)}
                    />,
                  ],
                }))}
                value={this.state.form.zone_id}
                onChange={v => this.change({ target: { value: v, name: 'zone_id' } })}
              />
            </div>

            <div className="col-6 col-xl-6">
              <SelectActions
                label="Categoría (Opcional)"
                placeholder="Seleccione"
                actionOptions={this.state.createFromPOS ? [] : [
                  {
                    onClick: () => this.createClientCategory(),
                    content: (
                      <div>
                        <span>Añadir</span>
                        <i className="fa fa-regular fa-plus" style={{ marginLeft: '0.75rem' }} />
                      </div>
                    ),
                  }
                ]}
                options={this.state.client_categories.map((i, idx) => ({
                  value: i.id,
                  label: i.name,
                  actions: this.state.createFromPOS ? [] : [
                    <i
                      key={`zone-${i.value}-edit`}
                      className="fa fa-regular fa-edit"
                      title="Editar"
                      onClick={() => this.editClientCategory(i, idx)}
                    />,
                    <i
                      key={`zone-${i.value}-close`}
                      className="fa fa-regular fa-close"
                      title="Eliminar"
                      onClick={() => this.deleteClientCategory(i, idx)}
                    />,
                  ],
                }))}
                value={this.state.form.client_category_id}
                onChange={v => this.change({ target: { value: v, name: 'client_category_id' } })}
              />
            </div>

            <div className="col-6 col-xl-6">
              <Select
                color="white"
                name="seller_id"
                label="Vendedor"
                defaultname="Seleccione"
                className="material"
                labelClass="material"
                disabledFirst={false}
                options={this.state.sellers.map(i => {
                  return {
                    value: i.id,
                    label: i.seller_code ? i.seller_code+' - '+i.name : i.name
                  };
                })}
                value={this.state.form.seller_id}
                onChange={v => {
                  this.change({
                    target: { value: +v.target.value, name: 'seller_id' }
                  });
                }}
              />
            </div>

            <div className="col-6 col-xl-6">
              <Input
                color=" "
                value={this.state.form.email}
                name="email"
                type="email"
                label="E-Mail"
                className="material"
                labelClass="material"
                onChange={this.change}
              />
            </div>

            <div className="col-6 col-xl-6">
              <Input
                type="number"
                value={this.state.form.credit_limit}
                name="credit_limit"
                label="Limite de crédito (Opcional)"
                labelClass="material"
                className="material"
                onChange={this.change}
              />
            </div>

            <div style={{ display: 'grid', flex: 1, padding: '1rem', gap: '1rem', gridTemplateColumns: 'repeat(2, 1fr)' }}>
              <div>
          
                {this.state.hasMapPermission ? (
                  <PlacesAutocomplete
                    value={this.state.form.address}
                    onChange={this.changeAddress}
                    onSelect={this.selectSuggestion}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        <div className="row">
                          <div className="col col-md">
                            <Textarea
                              label="Dirección"
                              name="address"
                              className="material"
                              labelClass="material"
                              {...getInputProps({
                                className: 'location-search-input',
                              })}
                            />
                          </div>
                        </div>
                        <div className="autocomplete-dropdown-container">
                          {loading && <div className="loading">Cargando...</div>}
                          {suggestions.map(suggestion => {
                            const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                            return (
                              <div {...getSuggestionItemProps(suggestion, { className })}>
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                ) : (
                  <div className="row">
                    <div className="col col-md">
                      <Textarea
                        label="Dirección"
                        name="address"
                        className="material"
                        labelClass="material"
                        rows={2}
                        value={this.state.form.address ? this.state.form.address : ""}
                        onChange={this.change}
                      />
                    </div>
                  </div>
                )}
              </div>

              {this.state.hasMapPermission && (
                <div className="client-gps">
                  <h5>Indicar ubicación GPS del cliente</h5>
                  <div id="map-create-client" />
                </div>
              )}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-6">
              <Select
                color=" "
                name="person_type"
                label="Personalidad Jurídica"
                defaultname="Seleccione"
                className="material"
                labelClass="material"
                disabledFirst="false"
                onChange={this.change}
                value={this.state.form.person_type}
                options={[
                  {
                    value: 1,
                    label: "Natural"
                  },
                  {
                    value: 2,
                    label: "Jurídica"
                  }
                ]}
              />
            </div>
            <div className="col-md-6">
              <Select
                color=" "
                name="retention_type_id"
                label="Tipo de Retencion"
                defaultname="Seleccione"
                className="material"
                labelClass="material"
                disabledFirst="false"
                onChange={this.change}
                value={this.state.form.retention_type_id}
                options={this.state.retentions.map(i => {
                  return {
                    value: i.id,
                    label: i.percentage
                  };
                })}
              />
            </div>
          </div> */}
          {/* <div className="row">
            <div className="col col-md">
              <Input
                color=" "
                name="days_deadline"
                label="Plazo de crédito"
                className="material"
                labelClass="material"
                maxLength={3}
                onKeyPress={e => {
                  Globals.soloNumeros(e);
                }}
                onChange={this.change}
                value={this.state.form.days_deadline}
              />
            </div>
          </div> */}

          {/*<CheckBox
                      label="Contribuyente Especial"
                      name="especial_contributor"
                      value={this.state.form.especial_contributor }
                      onChange={this.change}
                  />*/}
          <div id="button" className="d-flex justify-content-center">
            <Button color=" " className="primary" type="button" onClick={() => this.submit()}>
              <span>{this.state.textButton}</span>
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default connect(state => ({
  user: state.user,
}))(CreateEditClient);
